import { Box } from "@mui/material";
import WhyUnique from "./WhyUnique";
import FlightDeals from "./FlightDeals";
import Subscribe from "../components/Subscribe";
import SearchFlight from "./SearchFlight";
import Counters from "../components/Counters";
import WhyChooseUs from "./WhyChooseUs";
import GoogleReviews from "../components/googleReviews";
import Faqs from "../components/faqs";
import { useEffect, useState } from "react";
import Modal from "src/components/Modal";
import FloatingCallButton from "src/components/FloatingCallButton.tsx";
import FloatingContact from "src/components/FloatingCallButton.tsx";

const Home = () => {
  // const [searchModal, setSearchModal] = useState<boolean>(false);

  // useEffect(() => {
  //   const today = new Date().toDateString();

  //   console.log(today, "today");

  //   const lastVisited = localStorage.getItem("lastVisited");

  //   if (lastVisited !== today) {
  //     const timer = setTimeout(() => {
  //       setSearchModal(true);
  //       localStorage.setItem("lastVisited", today);
  //     }, 10000);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <SearchFlight />
      <WhyChooseUs />
      <Counters />
      <FlightDeals />
      <WhyUnique />
   
      <GoogleReviews />
      <Faqs />
      {/* <Modal
        sx={{
          width: { md: "30%" },
          mx: "auto",
          display: "flex",
          justifyContent: "center",
        }}
        open={searchModal}
        onClose={() => setSearchModal(false)}
      >
        <img
          style={{ width: "100%", marginBottom: "-6px" }}
          src="/assets/img/banner-mildwoods.jpg"
          alt="Your description"
        />
      </Modal> */}
      <Subscribe />
    </Box>
  );
};

export default Home;
