import { useCallback, useEffect } from "react";
import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import router from "src/router";
import { connect } from "react-redux";
import { CssBaseline, useTheme } from "@mui/material";
import { authUserProps, routeProps } from "./interfaces";
import Snackbar from "./components/Snackbar";
import {
  fetchAmadeusToken,
  removeFlights,
  removeRoute,
  resetSearchModel,
} from "./store";
import Spinner from "./components/Spinner";
import { Helmet } from "react-helmet-async";
import metaData from "./common/metadata.json";
import FloatingCallButton from "./components/FloatingCallButton.tsx";

function AppContent({
  auth,
  route,
  removeRoute,
  generateToken,
  resetModel,
  removeFlights,
}: {
  auth: authUserProps;
  route: routeProps;
  removeRoute: Function;
  generateToken: () => void;
  resetModel: () => void;
  removeFlights: Function;
}) {
  const content = useRoutes(router(auth?.role, auth?.isLoggedIn));
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // useEffect(() => {
  //   generateToken();
  // }, []);

  const scrollTop = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {
    if (route?.path) {
      scrollTop();
      navigate(route?.path, { state: route.params });
      removeRoute();
    }
  }, [navigate, route]);

  const pathname = location?.pathname;

  useEffect(() => {
    scrollTop();
    if (!pathname.includes("/flights")) {
      resetModel();
      removeFlights();
    }
  }, [pathname]);

  const canonicals = metaData?.canonicalTags;
  const metaKeywords = metaData?.metaKeywords;
  const HeadCallback = useCallback(() => {
    const page = pathname?.replaceAll("/", "");
    document
      .getElementById("canonical-tag-head")
      ?.setAttribute("href", canonicals[page] || "");
    document
      .getElementById("keyword-tag-head")
      ?.setAttribute("content", metaKeywords[page]?.keywords || "");
    document
      .getElementById("description-tag-head")
      ?.setAttribute("content", metaKeywords[page]?.description || "");
    return (
      <Helmet>
        <title>{metaKeywords[page]?.title}</title>
        <meta name="theme-color" content={theme?.colors?.secondary?.main} />
      </Helmet>
    );
  }, [pathname]);
  return (
    <>
      <HeadCallback />

      <Spinner />
      <Snackbar />
      <CssBaseline />
      {content}
    </>
  );
}
const mapStateToProps = ({ auth, route }) => ({ auth, route });
const mapDispatchToProps = (dispatch) => ({
  removeRoute: () => dispatch(removeRoute()),
  generateToken: () => dispatch(fetchAmadeusToken()),
  resetModel: () => dispatch(resetSearchModel()),
  removeFlights: () => dispatch(removeFlights()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
